<template>
  <div class="container">
    <div class="head">
      <div class="header_left">
        <a-form-model class="head_form" :mode="rankForm">
          <a-form-model-item label="姓名">
            <a-input placeholder="请输入姓名" v-model="rankForm.realName" :maxLength="20" />
          </a-form-model-item>
          <a-form-model-item label="手机号">
            <a-input placeholder="请输入手机号" v-model="rankForm.tel" :maxLength="20" />
          </a-form-model-item>
          <a-form-model-item label="教职工号">
            <a-input placeholder="请输入教职工号" v-model="rankForm.staff" :maxLength="20" />
          </a-form-model-item>
        </a-form-model>
        <div class="header_btn">
          <a-button class="search" @click="search">查询</a-button>
          <a-button class="reset" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="content">
      <a-table style="width: 100%" :columns="columns" :data-source="data" :pagination="false" :row-selection="rowSelection">
        <!-- 序号 -->
        <span slot="(index,text,record)" slot-scope="index, record, text">{{ text }}</span>
        <!-- 姓名 -->
        <span slot="username" slot-scope="text, records">
          <img class="avatar" :src="records.echoMap.avatar" alt="" />
          <span>{{ records.echoMap ? records.echoMap.realName : '--' }}</span>
        </span>
        <!-- 教职工号 -->
        <span slot="staff" slot-scope="text, records">
          {{ records.echoMap ? records.echoMap.staff : '--' }}
        </span>
        <!-- 所属党支部 -->
        <span slot="partyBranch" slot-scope="text, records">
          {{ records.echoMap ? records.echoMap.partyBranch : '--' }}
        </span>
        <!-- 手机号 -->
        <span slot="tel" slot-scope="text, records">
          {{ records.echoMap ? records.echoMap.tel : '--' }}
        </span>
        <!-- 综合评分 -->
        <span slot="synthesis" slot-scope="text, records">
          {{ records.synthesis ? records.synthesis.toFixed(1) : '--' }}
        </span>
        <!-- 堡垒评分 -->
        <span slot="bastion" slot-scope="text, records">
          {{ records ? records.bastion.toFixed(1) : '--' }}
        </span>
        <!-- 先锋评分 -->
        <span slot="pioneer" slot-scope="text, records">
          {{ records ? records.pioneer.toFixed(1) : '--' }}
        </span>
        <!-- 身份 -->
        <span slot="typeName" slot-scope="text, records">
          {{ records.echoMap ? records.echoMap.typeName : '--' }}
        </span>
        <span class="action" slot="action" slot-scope="text, records">
          <span @click="showInfo(records.id)" class="watch">查看</span>
        </span>
      </a-table>
      <Pagination :current="current" :page-size="size" :total="total" @change="change" />
    </div>
    <!-- 查看弹框 -->
    <a-modal v-model="visible" title="查看" @ok="handleOk" footer="" centered>
      <div class="main">
        <a-form-model :mode="watchForm">
          <div class="top">
            <div class="synthesis">
              <div class="title">
                综合积分
              </div>
              <div class="synthesiscode">
                {{ watchForm.synthesis ? watchForm.synthesis.toFixed(1) : '0.0' }}
              </div>
            </div>
            <div class="bastion">
              <div class="title">
                堡垒积分
              </div>
              <div class="bastioncode">
                {{ watchForm.bastion ? watchForm.bastion.toFixed(1) : '0.0' }}
              </div>
            </div>
            <div class="pioneer">
              <div class="title">
                先锋积分
              </div>
              <div class="pioneercode">
                {{ watchForm.pioneer ? watchForm.pioneer.toFixed(1) : '0.0' }}
              </div>
            </div>
          </div>
          <div class="middle">
            <div>
              <div class="title">
                学习星
              </div>
              <div>
                {{ watchForm.study ? watchForm.study.toFixed(1) : '0.0' }}
              </div>
            </div>
            <div>
              <div class="title">
                服务星
              </div>
              <div>
                {{ watchForm.service ? watchForm.service.toFixed(1) : '0.0' }}
              </div>
            </div>
            <div>
              <div class="title">
                工作星
              </div>
              <div>
                {{ watchForm.work ? watchForm.work.toFixed(1) : '0.0' }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <div>
              <div class="title">
                廉洁星
              </div>
              <div>
                {{ watchForm.honest ? watchForm.honest.toFixed(1) : '0.0' }}
              </div>
            </div>
            <div>
              <div class="title">
                道德星
              </div>
              <div>
                {{ watchForm.morality ? watchForm.morality.toFixed(1) : '0.0' }}
              </div>
            </div>
          </div>
        </a-form-model>

        <div class="footer">
          <a-button class="goback" @click="infocancel">返回</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { integration, integrationInfo } from '@/api/integration'
import Pagination from '@/components/pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      visible: false,
      selectedRowKeys: [],
      data: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          scopedSlots: { customRender: 'username' }
        },
        {
          title: '教职工号',
          dataIndex: 'staff',
          scopedSlots: { customRender: 'staff' }
        },
        {
          title: '所属党支部',
          dataIndex: 'partyBranch',
          scopedSlots: { customRender: 'partyBranch' }
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          scopedSlots: { customRender: 'tel' }
        },
        {
          title: '综合积分',
          dataIndex: 'synthesis',
          scopedSlots: { customRender: 'synthesis' }
        },
        {
          title: '堡垒积分',
          dataIndex: 'bastion',
          scopedSlots: { customRender: 'bastion' }
        },
        {
          title: '先锋积分',
          dataIndex: 'pioneer',
          scopedSlots: { customRender: 'pioneer' }
        },
        {
          title: '身份',
          dataIndex: 'typeName',
          scopedSlots: { customRender: 'typeName' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      size: 10,
      total: 0,
      current: 1,
      // 管理员
      checked: 0,
      rankForm: {
        realName: '',
        tel: '',
        staff: ''
      },
      watchForm: {}
    }
  },
  created() {
    this.getRankList()
  },
  methods: {
    // 获取榜单列表
    async getRankList() {
      const data = await integration({
        realName: this.rankForm.realName,
        tel: this.rankForm.tel,
        staff: this.rankForm.staff,
        current: this.current,
        size: this.size
      })
      if (data.code === 200) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 分页
    change(page, size) {
      this.current = page
      this.size = size
      this.getRankList()
    },
    onChange(e) {
      this.checked = e
      console.log(e)
    },
    // 查询
    search() {
      this.getRankList()
    },
    // 重置
    reset() {
      this.rankForm = {}
      this.getRankList()
    },
    async showInfo(id) {
      const data = await integrationInfo({ id: id })
      if (data.code === 200) {
        this.watchForm = data.data
      }
      console.log(this.watchForm)
      this.visible = true
    },
    handleOk() {},
    infocancel() {
      this.visible = false
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        Selections: [
          {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            }
          },
          {
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows)
            }
          },
          {
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows)
            }
          }
        ],
        onSelection: this.onSelection
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  background: #f8f9fe;
}

.head {
  background: #ffffff;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.header_left {
  display: flex;
  align-items: center;
}

>>> .ant-input {
  width: 200px;
  height: 36px;
}

.head_form {
  display: flex;
  align-items: center;
}

.head_form >>> .ant-form-item {
  display: flex;
}

>>> .ant-form-item {
  margin: 0;
  margin-right: 14px;
}

.search {
  width: 68px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 0 14px 0 6px;
}

.reset {
  width: 68px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.content {
  margin-top: 20px;
  background: #fff;
  height: calc(100vh - 196px);
  display: flex;
  flex-direction: column;
}

.content >>> .ant-table-wrapper {
  overflow-y: auto;
  flex: 1;
}

.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.watch {
  color: #f44600;
  cursor: pointer;
}

.content >>> .ant-spin-nested-loading {
  width: 100%;
}

>>> .ant-table-thead > tr > th {
  background: #f7f8f9 !important;
}

>>> .ant-table-tbody > tr > td {
  background: #fff !important;
}

* >>> .ant-modal-title {
  font-weight: bold;
  font-size: 16px;
  color: #353535;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main >>> .ant-form-item {
  display: flex;
  margin-bottom: 16px;
}

.formitem >>> .ant-input {
  width: 300px;
  height: 42px;
}

.formitem >>> .ant-form-item-label {
  width: 100px;
  text-align: right;
}

.formitem >>> .ant-select-selection--single {
  width: 300px;
  height: 42px;
}

.formitem >>> .ant-select-selection__rendered {
  height: 42px;
  display: flex;
  align-items: center;
}

.top {
  display: flex;
  background: #f7f7f7;
  padding: 20px;
  justify-content: space-between;
  text-align: center;
}

.middle {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  text-align: center;
}

.bottom {
  width: 59%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  text-align: center;
}

.title {
  color: #888888;
}

.synthesiscode {
  font-size: 20px;
  font-weight: bold;
  color: #f44600;
}

.bastioncode,
.pioneercode {
  font-size: 20px;
  font-weight: bold;
  color: #353535;
}
.goback {
  width: 98px;
  height: 36px;
  background: #fbfbfb;
  color: #353535;
}
</style>
